.embedding_slick-slider {
    position: relative;
    overflow: hidden;

    .embedding_slick-slider-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow: visible;
        transition: transform .5s;

        & > div:nth-child(1) { margin-left: 0;}
        & > div {
            flex: 0 0 300px;
            margin-left: 113px;
            margin-right: 24px;
            padding: 15px 0 40px 0;
            width: 300px;

            .embedding_slick-slider-title {
                margin-bottom: 16px;
                color: #1d2129;
                font-size: 24px;
                line-height: 32px;
                font-weight: 700;
                word-break: break-all;
            }

            .embedding_slick-slider-content {
                color: #1d2129;
                font-size: 17px;
                line-height: 24px;
                font-weight: 300;
            }
        }
    }

    .embedding_slick-slider-arrow-prev, .embedding_slick-slider-arrow-next {
        position: absolute;
        display: inline-block;
        top: 0;
        width: 80px;
        height: calc(100% - 3px);
        background: hsla(0,0%,100%,.9);
        z-index: 10;
        transition: opacity .25s;

        &.disabled { opacity: 0; }

        &.embedding_slick-slider-arrow-prev {
            left: 0;

            &:before { content: ""; }
        }

        &.embedding_slick-slider-arrow-next {
            right: 0;

            &:before { content: ""; }
        }

        &:before {
            display: inline-block;
            margin-top: -.2em;
            color: rgba(0,0,0,.54);
            font-size: 40px;
            font-weight: 400;
            line-height: 1;
            vertical-align: middle;
            text-transform: none;
            font-family: wadizicon!important;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            transition: transform .25s;
        }
    }

    .embedding_slick-slider-progress-wrapper {
        display: flex;
        justify-content: stretch;

        .embedding_slick-slider-progress {
            position: relative;
            flex: 1 1 auto;
            height: 3px;
            background-color: #F0F2F5;

            &.active:after { width: 100%; }
            &:after {
                position: absolute;
                display: inline-block;
                content: "";
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: #60656a;
                transition: width .5s;
            }
        }
    }
}

@media screen and (max-width: 634px) {
    .embedding_slick-slider {
        .embedding_slick-slider-arrow-prev, .embedding_slick-slider-arrow-next { display: none; }

        .embedding_slick-slider-wrapper > div { margin-left: 16px; }
    }
}