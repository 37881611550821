/* stylelint-disable indentation, value-keyword-case */

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

@font-face {
  font-family: 'Noto Serif KR';
  font-weight: $font-weight-light;
  font-style: normal;
  src: url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Light.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Light.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Light.otf') format('opentype');
  unicode-range: U+0020-U+007E, U+1100-U+11F9, U+3131-U+318E, U+327F-U+327F, U+AC00-U+D7A3, U+FF01-U+FF60;
  // U+0020-U+007E (로마자 기본)
  // https://ko.wikipedia.org/wiki/%EB%A1%9C%EB%A7%88%EC%9E%90_%EA%B8%B0%EB%B3%B8
  // U+1100-U+11F9 (한글 자소)
  // https://en.wikipedia.org/wiki/Hangul_Jamo_(Unicode_block)
  // U+3131-U+318E (한글 낱자)
  // https://ko.wikipedia.org/wiki/%ED%95%9C%EA%B8%80_%EB%82%B1%EC%9E%90_%EB%AA%A9%EB%A1%9D
  // U+327F (KS마크)
  // http://www.fileformat.info/info/unicode/char/327f/index.htm
  // U+AC00-U+D7A3 (한글 음절)
  // https://en.wikipedia.org/wiki/Hangul_Syllables
  // https://en.wiktionary.org/wiki/Appendix:Unicode/Hangul_Syllables
  // U+FF01-U+FF60 (Halfwidth and Fullwidth Forms)
  // http://unicode.org/charts/PDF/UFF00.pdf
}

@font-face {
  font-family: 'Noto Serif KR';
  font-weight: $font-weight-regular;
  font-style: normal;
  src: url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Regular.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Regular.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Regular.otf') format('opentype');
  unicode-range: U+0020-U+007E, U+1100-U+11F9, U+3131-U+318E, U+327F-U+327F, U+AC00-U+D7A3, U+FF01-U+FF60;
}

@font-face {
  font-family: 'Noto Serif KR';
  font-weight: $font-weight-medium;
  font-style: normal;
  src: url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Medium.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Medium.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Medium.otf') format('opentype');
  unicode-range: U+0020-U+007E, U+1100-U+11F9, U+3131-U+318E, U+327F-U+327F, U+AC00-U+D7A3, U+FF01-U+FF60;
}

@font-face {
  font-family: 'Noto Serif KR';
  font-weight: $font-weight-bold;
  font-style: normal;
  src: url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Bold.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Bold.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/notoserif/NotoSerifKR-Bold.otf') format('opentype');
  unicode-range: U+0020-U+007E, U+1100-U+11F9, U+3131-U+318E, U+327F-U+327F, U+AC00-U+D7A3, U+FF01-U+FF60;
}


