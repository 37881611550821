// html, body, #root { width: 100%; height: 100%; }
* { font-family: Roboto, "Noto Sans KR", -apple-system, Dotum, sans-serif !important; }

.embedding_widget-embed {
    display: flex;
    flex-direction: column;
    height: 525px;
    // border-radius: 4px;

    .embedding_image {
        flex: 0 0 152px;
        min-height: 152px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        // border-radius: 3px;
    }

    hr {
        margin: 0;
        height: 0;
        border-style: solid;
        border-color: #00b2b2;
        border-width: 4px 0 0 0;
    }

    .embedding_border-wrap {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        border-style: solid;
        border-color: #ededed;
        border-width: 0 1px 1px 1px;

        .embedding_information {
            display: flex;
            flex: 0 0 50px;
            align-items: center;
            padding: 0 15px;
            height: 50px;
            background-color: #fafafa;
            border-style: solid;
            border-color: #ededed;
            border-width: 0 0 1px 0;

            .left {
                span.embedding_progress {
                    margin-right: 10px;
                    color: #00c4c4;
                    font-size: 17px;
                    font-weight: bold;
                    letter-spacing: -1px;

                    em {
                        margin-left: 2px;
                        font-size: 12px;
                        font-weight: normal;
                    }

                    em.embedding_progress-detail {
                        margin-left: 5px;
                        color: #3a3a3a;
                    }
                }
            }

            .right {
                margin-left: auto;

                span.badge {
                    padding: 0;
                    width: 50px;
                    color: #92969e;
                    font-size: 11px;
                    text-align: center;
                    border: 1px solid #92969e;
                    background-color: #fff;
                }

                span.embedding_state {
                    color: #00a2a2;
                    font-size: 13px;
                    font-weight: bold;
                }
                
                span.embedding_amount {
                    color: #9e9e9e;
                    font-size: 13px;
                    letter-spacing: -1px;
                    margin-left: 4px;
                }
            }
        }

        .embedding_provider {
            display: flex;
            flex: 0 0 80px;
            padding: 0 11px 0 13px;
            height: 80px;
            align-items: center;

            .embedding_icon {
                display: inline-block;
                flex: 0 0 auto;
                margin-right: 10px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border: 1px solid #d4efeb;
            }

            span {
                display: inline-block;
                flex: 0 0 171px;
                width: 171px;
                font-size: 13px;
                line-height: 18px;
                font-weight: bold;            
            }
        }

        div.embedding_title {
            flex: 0 0 auto;
            margin-bottom: 14px;
            padding: 0 11px;

            & > span {
                display: inline-block;
                display: -webkit-box;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2;
                word-wrap: break-word;
                height: 1.2em;
                color: #3a3a3a;
                font-size: 17px;
                font-weight: 900;

                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        div.embedding_summary {
            flex: 1 1 auto;
            margin-bottom: 22px;
            padding: 0 11px;

            & > span {
                display: inline-block;
                display: -webkit-box;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2;
                word-wrap: break-word;
                height: 3.6em;
                color: #3a3a3a;
                font-size: 12px;

                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        button {
            flex: 0 0 auto;
            height: 48px;
            border: solid 1px #e2e3e6;
            background-color: #ffffff;
            margin: 20px 12px 30px 12px;
            color: #9a9a9a;
            font-size: 17px;
            cursor: pointer;

            i {
                display: inline-block;
                margin-left: 12px;
                width: 7px;
                height: 12px;
                background: url("../../Assets/Images/btn-right.png") no-repeat;
            }
        }

        .embedding_logo {
            a {
                display: inline-flex;
                align-items: center;
                margin: 0 0 13px 13px;

                img {
                    width: 55px;
                    height: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 685px) {
    .embedding_widget-embed {
        .embedding_border-wrap {
            .embedding_logo {
                a {
                    margin: 0 0 10px 10px;
                    img {
                        width: 40px;
                        height: 11px;
                    }
                }
            }
        }
    }
}