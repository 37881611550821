#toastWrap {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    // pointer-events: none;

    .embedding_toast-msg {
        display: inline-block;
        margin-bottom: 10px;
        padding: 10px 30px;
        min-width: 200px;
        color: #fff;
        background-color: rgba(0,0,0,.8);
        border-radius: 20px;
        text-align: center;
        box-shadow: 0 1px 10px 4px rgba(0,0,0,.1);
        opacity: 0;
        transition: opacity .4s;

        &.active { opacity: 1; }
    }
}