// html, body, #root { width: 100%; height: 100%; }
* { font-family: Roboto, "Noto Sans KR", -apple-system, Dotum, sans-serif !important; }

.embedding_story-embed {
    display: flex;
    flex-direction: column;
    height: 1220px;

    .embedding_image {
        flex: 0 0 387px;
        height: 387px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    hr {
        margin: 0;
        height: 0;
        border-style: solid;
        border-color: #00b2b2;
        border-width: 5px 0 0 0;
    }

    .embedding_border-wrap {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        border-style: solid;
        border-color: #ededed;
        border-width: 0 1px 1px 1px;
        overflow: auto;

        .embedding_information {
            display: flex;
            flex: 0 0 69px;
            align-items: center;
            padding: 0 25px;
            height: 69px;
            background-color: #fafafa;
            border-style: solid;
            border-color: #ededed;
            border-width: 0 0 1px 0;

            .left {
                span.embedding_progress {
                    color: #00c4c4;
                    font-size: 28px;
                    font-weight: bold;
                    letter-spacing: -1px;

                    em {
                        margin-left: 3px;
                        font-size: 19px;
                        font-weight: normal;
                    }

                    em.embedding_progress-detail {
                        margin-left: 8px;
                        color: #3a3a3a;
                    }
                }
            }

            .right {
                margin-left: auto;

                span.embedding_state {
                    color: #00a2a2;
                    font-size: 19px;
                    font-weight: bold;
                }

                span.embedding_amount {
                    color: #9e9e9e;
                    font-size: 19px;
                    letter-spacing: -1px;
                    margin-left: 10px;
                }
            }
        }

        .embedding_provider {
            display: flex;
            flex: 0 0 96px;
            padding: 0 24px 0 24px;
            height: 96px;
            align-items: center;

            .embedding_icon {
                display: inline-block;
                flex: 0 0 auto;
                margin-right: 10px;
                width: 52px;
                height: 52px;
                border-radius: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border: 1px solid #d4efeb;
            }

            span {
                display: inline-block;
                flex: 0 0 auto;
                color: #050505;
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
            }
        }

        div.embedding_title {
            flex: 0 0 auto;
            margin-bottom: 28px;
            padding: 0 24px;

            & > span {
                display: inline-block;
                display: -webkit-box;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2;
                word-wrap: break-word;
                height: 2.4em;
                color: #3a3a3a;
                font-size: 21px;
                font-weight: 900;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        div.embedding_summary {
            flex: 0 0 37px;
            height: 37px;
            margin-bottom: 29px;
            padding: 0 24px;

            & > span {
                display: inline-block;
                display: -webkit-box;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.2;
                word-wrap: break-word;
                height: 3.6em;
                color: #3a3a3a;
                font-size: 15px;

                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        div.embedding_button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 27px;
            margin: 0 24px;
            border-bottom: 2px solid #ededed;

            button {
                width: 226px;
                height: 48px;
                // border-radius: 1px;
                border: solid 1px #e2e3e6;
                background-color: #ffffff;
                color: #9a9a9a;
                font-size: 17px;
                cursor: pointer;

                i {
                    display: inline-block;
                    margin-left: 12px;
                    width: 7px;
                    height: 12px;
                    background: url("../../Assets/Images/btn-right.png") no-repeat;
                }
            }
        }

        div.embedding_detail {
            flex: 1 1 auto;
            margin-bottom: 72px;
            padding: 0 24px;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;

            .embedding_section {
                padding-top: 32px;
                padding-bottom: 32px;

                .embedding_section-title {
                    margin: 0 16px 16px;
                    line-height: 24px;
                    letter-spacing: 0;
                    color: rgba(0,0,0,.87);
                    font-size: 19px;
                    font-weight: 700;
                }
                .embedding_section-content { margin: 0 16px; }
            }
            * { word-break: break-all; }
            img { width: 100%; }
        }

        .embedding_logo {
            a {
                display: inline-flex;
                align-items: center;
                margin: 0 0 24px 24px;

                img {
                    width: 67px;
                    height: 19px;
                }
            }
        }
    }
}

@media screen and (max-width: 685px) {
    .embedding_story-embed {
        height: 881px;

        .embedding_image {
            flex: 0 0 197px;
            height: 197px;
        }

        .embedding_border-wrap {
            .embedding_information {
                height: 49px;
                padding: 17px;
                .left span.embedding_progress {
                    font-size: 19px;

                    em { font-size: 15px; }
                }

                .right span.embedding_amount { font-size: 17px; }
            }

            .embedding_provider {
                flex: 0 0 73px;
                height: 73px;

                i.embedding_icon {
                    width: 52px;
                    height: 52px;
                }
                span { font-size: 13px; }
            }

            div.embedding_title {
                flex: 0 0 39px;
                height: 39px;
                margin-bottom: 16px;
                & > span { font-size: 17px; }
            }

            div.embedding_summary {
                flex: 0 0 43px;
                height: 43px;
                margin-bottom: 16px;
                & > span { font-size: 12px; }
            }

            div.embedding_button {
                & > button {
                    width: 100%;
                    height: 45px;
                    font-size: 15px;
                }
            }

            div.embedding_detail {
                margin-bottom: 42px;
                padding: 0 17px;
            }

            .embedding_logo {
                a {
                    margin: 0 0 10px 10px;
                    img {
                        width: 55px;
                        height: 16px;
                    }
                }
            }
        }
    }
}