#root * { font-family: 'Noto Sans KR', Roboto, -apple-system, Dotum, sans-serif !important; }
#root .web-header * { font-family: Roboto, 'Noto Sans KR', -apple-system, Dotum, sans-serif !important; }

#embedMain {
    font-size: 15px;
    background-color: #f0f2f5;

    .embedding_video-wrap, .embedding_story-wrap, .embedding_widget-wrap, .embedding_project-link-wrap {
        margin-bottom: 16px;
        padding: 30px 24px 30px 22px;
        background-color: #fff;
    }

    .embedding_tab-wrap {
        .embedding_tab-list { display: none; }

        .embedding_video-wrap {
            display: flex;
            flex-direction: column;

            & > h2 {
                display: block;
                margin-bottom: 19px;
                font-size: 17px;
            }
            .embedding_video-content {
                margin-top: 19px;
                
                .embedding_video { margin-bottom: 14px; background-color: #000; }

                .embedding_size {
                    display: flex;

                    .embedding_select-size {
                        display: flex;
                        flex: 1 1 auto;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-right: 10px;
                        border-right: 1px solid #f4f4f4;
                        margin-right: 10px;
    
                        .wz.text.caption2 {
                            display: block;
                            margin-bottom: 10px;
                            color: #272727;
                            font-size: 12px;
                            font-weight: bold;
                            word-break: break-all;
                            letter-spacing: -0.8px;
                        }
    
                        .embedding_button-wrap {
                            display: flex;
                            // justify-content: space-between;
    
                            button {
                                margin-right: 5px;
                                padding: 0;
                                width: 57px;
                                height: 25px;
                                color: #3a3a3a;
                                font-size: 10px;
                                background-color: #f7f7f7;
                                border-radius: 0;
    
                                &.active {
                                    color: #fff;
                                    border-color: #00b2b2;
                                    background-color: #00b2b2;
                                }

                                &:nth-last-child(1) { margin-right: 0; }
                            }
                        }
                    }
    
                    .embedding_custom-size {
                        display: flex;
                        flex: 0 0 auto;
                        flex-direction: column;
                        justify-content: space-between;
    
                        .wz.text.caption2 {
                            display: block;
                            margin-bottom: 10px;
                            color: #878787;
                            font-size: 12px;
                            text-align: right;
                        }
    
                        .embedding_input-wrap {
                            display: flex;
    
                            .wz.input > input {
                                padding: 0 5px;
                                width: 56px;
                                height: 24px;
                                border-radius: 0;
                                font-size: 10px;
                                text-align: center;
                            }
    
                            span {
                                margin: 0 4px 0 5px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
            
        }

        .embedding_story-wrap {
            display: flex;
            flex-direction: column;
            
            & > h2 {
                display: block;
                margin-bottom: 19px;
                font-size: 17px;
            }

            .embedding_story-embed-wrap {
                margin-top: 20px;
            }
        }
    }

    .embedding_widget {
        .embedding_widget-wrap {
            h2.wz.text.title {
                font-size: 17px;
                margin-bottom: 20px;
                height: 39px;
            }

            .embedding_widget-embed-wrap {
                text-align: center;
                margin-top: 20px;
            }
        }

        .embedding_project-link-wrap {
            h2.wz.text.title {
                font-size: 17px;
                margin-bottom: 10px;
            }

            .embedding_source-wrap {
                & > div.wz.input input {
                    flex: 1 1 auto;
                    height: 31px;
                }

                & > button.wz.button {
                    flex: 0 0 65px;
                    width: 65px;
                    height: 31px;
                }
            }
        }
    }
}

#embedMain .embedding_source-wrap {
    display: flex;
    align-items: stretch;

    & > div {
        flex: 1 1 auto;
        margin-right: 4px;

        textarea {
            padding: 13px 19px;
            font-size: 12px;
            word-break: break-all;
            border: 1px solid #ededed;
            background-color: #fff;
            resize: none;
            pointer-events: all;
        }

        input {
            font-size: 12px;
            border: 1px solid #ededed;
            background-color: #fff;
        }
    }
    
    button.copy-btn {
        flex: 0 0 68px;
        padding: 0;
        width: 68px;
        height: auto;
        font-size: 12px;
        // border-radius: 2px;
        border: solid 1px #cacaca;
        background-color: #606060;
    }

    button.copy-btn + div.DialogModal_dialogPortal__32AHN { position: absolute; }
}

@media screen and (min-width: 769px) {
    #embedMain {
        display: flex;
        justify-content: center;
        margin: 110px 0 120px 0;
        background-color: #fff;

        .embedding_video-wrap, .embedding_story-wrap, .embedding_widget-wrap, .embedding_project-link-wrap {
            margin: 0;
            padding: 0;
        }

        .embedding_tab-wrap {
            display: flex;
            flex-direction: column;

            .embedding_tab-list {
                display: flex;
                flex: 0 0 auto;

                li {
                    padding-bottom: 10px;
                    width: 144px;
                    color: #888787;
                    text-align: center;
                    border-bottom: 1px solid #20bdbd;
                    cursor: pointer;

                    &.active {
                        color: #3a3a3a;
                        border-bottom: 4px solid #20bdbd;
                    }
                    
                    h2.wz.text.title { font-size: 17px; }
                }
            }

            .embedding_video-wrap {
                flex: 1 1 auto;
                display: none;
                margin: 20px 30px 0 0;
                padding-right: 30px;
                width: 686px;
                border-right: 1px solid #d4d4d4;
                box-sizing: content-box;

                &.active { display: flex; }
                & > h2 { display: none; }

                .embedding_video-content {
                    padding: 24px;
                    border: solid 1px #ededed;
                    background-color: #ffffff;

                    .embedding_size {
                        padding: 0px 20px;

                        & > .embedding_select-size > .embedding_button-wrap > button {
                            margin-right: 13px;
                            width: 92px;
                            height: 34px;
                            font-size: 13px;
                            // border-radius: 3px;
                        }

                        & > .embedding_custom-size > .embedding_input-wrap {
                            & > .wz.input > input {
                                width: 62px;
                                height: 34px;
                                // border-radius: 3px;
                                font-size: 13px;
                            }

                            & > span { line-height: 28px; }
                        }
                    }
                }
            }

            .embedding_story-wrap {
                flex: 1 1 auto;
                display: none;
                margin: 20px 30px 0 0;
                padding-right: 30px;
                width: 686px;
                border-right: 1px solid #d4d4d4;
                box-sizing: content-box;

                &.active { display: flex; }
                & > h2 { display: none; }
            }
        }

        .embedding_widget {
            width: 252px;

            .embedding_widget-wrap {
                margin-bottom: 80px;

                // .source-wrap {
                //     button {
                //         flex: 0 0 56px;
                //         padding: 0 15px;
                //         width: 56px;
                //     }
                // }
            }
        }
    }
}