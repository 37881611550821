html, body, #root { overflow: auto; }
* {
    outline: none;
    
    &:not(html):not(body):not(#root) {
        &::-webkit-scrollbar { width: 4px; height: 4px; }
        &::-webkit-scrollbar-track { background-color: transparent; }
        &::-webkit-scrollbar-thumb {
            background-color: #e4e7e5;
            border-radius: 2px;
        }
    }
}